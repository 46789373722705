exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-adepts-tsx": () => import("./../../../src/pages/adepts.tsx" /* webpackChunkName: "component---src-pages-adepts-tsx" */),
  "component---src-pages-contacts-tsx": () => import("./../../../src/pages/contacts.tsx" /* webpackChunkName: "component---src-pages-contacts-tsx" */),
  "component---src-pages-first-module-tsx": () => import("./../../../src/pages/first-module.tsx" /* webpackChunkName: "component---src-pages-first-module-tsx" */),
  "component---src-pages-fourth-module-tsx": () => import("./../../../src/pages/fourth-module.tsx" /* webpackChunkName: "component---src-pages-fourth-module-tsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-second-module-tsx": () => import("./../../../src/pages/second-module.tsx" /* webpackChunkName: "component---src-pages-second-module-tsx" */),
  "component---src-pages-three-module-tsx": () => import("./../../../src/pages/three-module.tsx" /* webpackChunkName: "component---src-pages-three-module-tsx" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

